<template>
<div class="recruit-box left clearfix">
  <Header></Header>
  <!--顶部banner开始-->
  <div class="aboutus-banner left"> <img src="~images/banner/xinxiangmuzixun.jpg" width="100%" /> </div>

  <div class="contact-content width-bai left clearfix">
                <div class="contact-contents center clearfix" style="padding-top:30px;">

                <h1 style="margin-left:30px;">请选择您要咨询的项目</h1>
                <div class="recruit-form">
                    <div class="recruit-form-div center">
                    <router-link to="/install" title="报装">
                    <div class="recruit-form-divs" style="margin-left:70px;"><label><Icon type="md-construct" /></label><label>报装</label></div>
                    </router-link>
                    <router-link to="/capacity" title="增容">
                    <div class="recruit-form-divs"><label><Icon type="ios-cube" /></label><label>增容</label></div>
                    </router-link>
                    <router-link to="/reform" title="改造">
                    <div class="recruit-form-divs"><label><Icon type="md-medal" /></label><label>改造（迁移）</label></div>
                    </router-link>
                    <p>选择您想要咨询的项目，我们的客服会尽快与您取得联系，如有疑问也可以致电<label style="color:rgb(242,151,0);font-weight:bold;"> 400-030-1913 </label>进行咨询。</p>
                    </div>
                </div>
                </div>
  </div>
  
  <div class="left clearfix width-bai"> <Footer></Footer> </div>
  <contact-call></contact-call>
</div>
</template>
<style>
    .recruit-form-div p{line-height:30px;font-size:15px;color:#666;float:left;padding:20px;}
    .recruit-form-div{width:700px;height:300px;box-shadow: 0px 2px 5px #cccccc;margin-top:170px;}
    .recruit-form-divs label:last-child{font-size:16px;cursor:pointer;}
    .rrecruit-form-divs label:first-child{font-size:22px;margin-right:5px;cursor:pointer;}
    .recruit-form-divs:hover{background:rgb(230, 141, 16);}
    .recruit-form-divs{width:150px;line-height:50px;background:rgb(242,151,0);cursor:pointer;color:#fff;border-radius:5px;margin:25px;float:left;text-align:center;margin-top:100px;}
    .recruit-box h1{font-size:16px;border-left:5px solid rgb(242,151,0);padding-left:10px;margin-bottom:40px;}
    .contact-text{width:100%;min-height:800px;margin-top:20px;}
    .contact-contents{min-height:900px;width:1440px;margin-top:10px;font-size:16px;box-shadow: 0px 2px 5px #cccccc;margin-top:50px;padding-bottom:50px;}
    .contact-content{min-height:900px;}
    
</style>

<script>
import $ from 'jquery'
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default {
        data () {
            
            return {
                labelPosition:'top',
                data:'',

                formItem: {
                    project_title:'',
                    project_number:'',
                    project_user:'',
                },

                ruleValidate: {
                    project_title: [
                        { required: true, message: '请填写项目名称', trigger: 'blur' }
                    ],
                    project_number: [
                        { required: true, message: '请填写项目编号', trigger: 'blur' }
                    ],
                    project_user: [
                        { required: true, message: '请填写项目负责人', trigger: 'blur' }
                    ]
                }
            }
        },

        components:{
            ContactCall,
            Header,
            Footer
        },

methods: {
handleSubmit (name) {
    $('#propess').hide();
    this.$refs[name].validate((valid) => {
        if (valid) {
            this.formItem.token=this.$store.state.token;
            var param=JSON.stringify(this.formItem);
            param = eval('(' + param + ')');

            this.$axios({method:'post',url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_project_progress.html',params:param})
						.then(res=>{
               if(res.data.code=='000'){
                 if(res.data.result){
                    this.data=res.data.result;
                    $('#propess').show();
                 }else{
                     this.$Modal.error({
                        title: '温馨提示',
                        content: '没有查询到对应的记录'
                    });
                 }
               }
            });
            
        } 
    })
}
            
}

}
</script>

